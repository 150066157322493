import {Injectable} from '@angular/core';
import {ErrorModel} from '../models/error.model';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  public log(error: ErrorModel){
    console.log(error);
  }
  public info(message: any){
    if(message instanceof String)
      console.log(message);
    else
      console.log(JSON.stringify(message));
  }
}
