import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {environment} from '../environments/environment';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthenticationService} from './core/services/auth.service';
import {throwError} from 'rxjs';

@Injectable()
export class APIInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthenticationService) { }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {

    console.log("http error in ",err.status,err.url, "type ",err.type);
    if (err.status === 401 && err.url.indexOf('login') < 0) {
      //log the user out
      console.log("logging out");
      this.authService.logout();
      this.router.navigateByUrl(`/account/login`);
    }
    // else if(err.status === 500 && err.headers.get("method"))
    else
      return throwError(err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiReq = req.clone({
      url: environment.apiServer + req.url,
      // setHeaders:{
      //   Authorization : `Bearer ${localStorage.getItem("access_token")}`
      // }
    });
    return next.handle(apiReq).pipe(catchError(x=>this.handleAuthError(x)));
  }
}
