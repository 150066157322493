import {Injectable} from '@angular/core';
import {ApiResponseModel} from '../models/api-response.model';
import {ErrorModel} from '../models/error.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApirequestService {

  constructor(private httpClient: HttpClient) { }

  public get(url: string): Promise<ApiResponseModel>{
    return new Promise<ApiResponseModel>((resolve,reject)=>{
      this.httpClient.get<ApiResponseModel>(url)
        .subscribe((res)=>{
            if(res.status == 'success'){
              resolve(res);
            }
            else{
              reject(res);
            }
          },
          (err)=> {
            reject('There was an error in processing your request. Please try again later')
            throw new ErrorModel(err);
          }
        );
    })
  }

  public post(url: string, data:any): Promise<ApiResponseModel>{
    return new Promise<ApiResponseModel>((resolve,reject)=>{
      this.httpClient.post<ApiResponseModel>(url, data)
        .subscribe((res)=>{
            if(res.status == 'success'){
              resolve(res);
            }
            else{
              reject(res);
            }
          },
          (err)=> {
            reject('There was an error in processing your request. Please try again later')
            throw new ErrorModel(err);
          }
        );
    })
  }
}
