import {Component, Input, OnInit} from '@angular/core';
import {Constants} from '../../../constants';
import {FormGroup} from '@angular/forms';


@Component({
  selector: 'app-form-status-dropdown',
  templateUrl: './form-status-dropdown.component.html',
  styleUrls: ['./form-status-dropdown.component.scss']
})
export class FormStatusDropdownComponent implements OnInit {
  @Input() boolean_type: boolean = false;
  @Input() form: FormGroup;
  @Input() controlName: string = 'status';

  options = [];

  constructor() { }

  ngOnInit(): void {
    if(this.boolean_type)
      this.options = [
        {value: Constants.YES_FLAG, name: 'Yes'},
        {value: Constants.NO_FLAG, name: 'No'},
      ];
    else
      this.options = [
        {value: Constants.STATUS_ACTIVE, name: 'Active'},
        {value: Constants.STATUS_INACTIVE, name: 'Inactive'},
      ];
  }

}
