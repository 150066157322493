import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  public success(message: string, title: string = 'Good job!'){
    Swal.fire({
      title: title,
      html: message,
      timer: 2000,
      icon: 'success',
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });


  }

  public error(message: string, title: string = 'Oops!'){
    Swal.fire({
      title: title,
      html: message,
      timer: 2000,
      icon: 'error',
    });
  }
}
