import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {LoggerService} from './core/services/logger.service';
import {ErrorModel} from './core/models/error.model';

@Injectable()
export class AppErrorHandler implements ErrorHandler {


  constructor(private injector: Injector){}

  handleError(error: any): void {
    const loggerService = this.injector.get(LoggerService);
    let errorModel = new ErrorModel(error);

    loggerService.log(errorModel);

    //TODO add error handling logic

  }

}
