import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-recoverpassword',
  templateUrl: './recoverpassword.component.html',
  styleUrls: ['./recoverpassword.component.scss']
})

/**
 * Recover password component
 */
export class RecoverpasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
