export class ErrorModel{
  public error?: any;
  public message: string;
  public errorCode?: number;

  constructor(error: any, message?: string|null, code?: number|null){
    this.error = error;
    this.message = (message ? message : 'An error has occured');
    this.errorCode = (code ? code : 500);
  }
}
