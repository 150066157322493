import {Component, OnInit} from '@angular/core';
import {CommonServices} from "../../core/common-services";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  // set the currenr year
  year: number = new Date().getFullYear();

  constructor(
    public commonServices: CommonServices
  ) { }

  ngOnInit() {
  }

}
