export class Constants{
  public static CMD_INVALID_REQUEST = 1;
  public static CMD_ACCOUNT_ACTIVATED = 2;
  public static CMD_PASSWORD_RESET = 3;

  public static CAMPAIGN_STATUS_ACTIVE = 1;
  public static CAMPAIGN_STATUS_INACTIVE = 2;
  public static CAMPAIGN_STATUS_COMPLETED = 3;

  public static OFFER_APPROVED = 'approved';
  public static OFFER_REJECTED = 'rejected';
  public static OFFER_APPROVAL_PENDING = 'pending';
  public static OFFER_NOT_APPLIED = 'not_applied';

  public static YES_FLAG = 1;
  public static NO_FLAG = 0;

  public static ASSET_LOGO = 1;
  public static ASSET_FAVICON = 2;
  public static ASSET_LOGO_LIGHT = 3;

  public static SIGNUP_FIELD_TYPE_INPUT = 'input';
  public static SIGNUP_FIELD_TYPE_DROPDOWN = 'select';
  public static SIGNUP_FIELD_TYPE_TEXTAREA = 'textarea';

  public static STATUS_ACTIVE = 'active';
  public static STATUS_INACTIVE = 'inactive';

  public static USER_STATUS_ACTIVE = 1;
  public static USER_STATUS_INACTIVE = 2;
  public static USER_STATUS_BANNED = 3;
  public static USER_STATUS_PENDING_APPROVAL = 4;

  public static OFFER_CREATIVE_TYPE_HTML = 'html';
  public static OFFER_CREATIVE_TYPE_BANNER = 'banner';
  public static ADVERTISER_PLATFORM_TYPE_HASOFFERS = 'hasoffers';

  public static ADVERTISER_INTEGRATION_TYPE_AFFWISE = 'affwise';
  public static ADVERTISER_INTEGRATION_TYPE_HASOFFERS = 'hasoffers';
  public static ADVERTISER_INTEGRATION_TYPE_CPALEAD = 'cpalead';

  public static ADVERTISER_PLATFORM_TYPES = [
    {name: 'Hasoffers', value: Constants.ADVERTISER_PLATFORM_TYPE_HASOFFERS }
  ];

  public static ADVERTISER_INTEGRATION_TYPES = [
    {name: 'Hasoffers', value: Constants.ADVERTISER_INTEGRATION_TYPE_HASOFFERS },
    {name: 'AffWise', value: Constants.ADVERTISER_INTEGRATION_TYPE_AFFWISE },
    {name: 'CPALead', value: Constants.ADVERTISER_INTEGRATION_TYPE_CPALEAD },
  ];

  public static getRangeFromPeriod(period: any){
    let start = new Date();
    let end = new Date();

    switch(period){
      case 'yesterday':
        start = new Date(start);
        start.setDate(start.getDate() - 1)

        end = start;
        break;

      case 'mtd':
        start = new Date(start.getFullYear(), start.getMonth(), 1);
        end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
        break;

      case 'ytd':
        start = new Date(start.getFullYear(), 0, 1);
        end = new Date(start.getFullYear(), 11, 31);
        break;
    }

    return {start: start, end: end};
  }
}
