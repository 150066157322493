import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {environment} from '../environments/environment';

import {JwtModule} from '@auth0/angular-jwt';

import {ExtrapagesModule} from './extrapages/extrapages.module';

import {LayoutsModule} from './layouts/layouts.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {APIInterceptor} from './api-request-interceptor';
import {AppErrorHandler} from './app-error.handler';

import { QuillModule } from 'ngx-quill'


// initFirebaseBackend(environment.firebaseConfig);

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    ExtrapagesModule,
    QuillModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: environment.domains,
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: APIInterceptor,
    multi: true,
  },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
  ]
})
export class AppModule { }
