import {Injectable} from '@angular/core';

import {User} from '../models/auth.models';
import {HttpClient} from '@angular/common/http';
import {ApiResponseModel} from '../models/api-response.model';
import {ApirequestService} from './apirequest.service';
import {Router} from "@angular/router";

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor(private httpClient: HttpClient,
              private router: Router,
                private apiRequest: ApirequestService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
      if(localStorage.getItem('user_identity')){
        this.user = JSON.parse(localStorage.getItem('user_identity'));

      }
      if(this.user?.hash) {
        localStorage.setItem('access_token',this.user?.hash);

        if(!localStorage.getItem('user_identity'))
          localStorage.setItem('user_identity',JSON.stringify(this.user));

        return this.user;
      }
      else
        return this.logout();
    }

    /**
     * Performs the auth
     * @param username username of user
     * @param password password of user
     */
    login(username: string, password: string): Promise<User> {

        return new Promise<User>((resolve,reject)=>{
          this.httpClient.post<User>('auth/login',{
            username: username,
            password: password
          }).subscribe((user)=>{
            console.log("Logged in",JSON.stringify(user));
            this.user = user;
            localStorage.setItem('user_identity',JSON.stringify(this.user));

            resolve(user);
          },
      (err)=>{

              reject(err.error.message);
            }
          );
        })

    }

    /**
     * Performs the register
     */
    register(values:object): Promise<ApiResponseModel> {
      return this.apiRequest.post('auth/signup',values);
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string): Promise<ApiResponseModel> {
      return this.apiRequest.post('auth/forgot-password',{email: email});
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
      delete this.user;
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_identity');
        return null;
        // getFirebaseBackend().logout();
    }


    loginAs(user, user_type: string){
      var r = confirm("This will log you out from current logged in account.");
      if (r === true) {
        return new Promise<User>((resolve,reject)=>{
          this.httpClient.post<User>('admin-users/login-as',{
            id: user?.id,
            user_type,
          }).subscribe((user)=>{
              //delete old
              this.logout();

              this.user = user;
              localStorage.setItem('user_identity', JSON.stringify(this.user));
              localStorage.setItem('access_token', this.user.hash);

              console.log(this.user);

              resolve(user);
              setTimeout(e=>{
                window.location.replace(window.location.origin);
              },1000);
            },
            (err)=>{

              reject(err.error.message);
            }
          );
        })
      }
    }

}

