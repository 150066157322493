import {Component} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {CommonServices} from "./core/common-services";
import {NotificationService} from "./core/services/notification.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public authRoutes = [
    '/account/login',
    '/account/signup',
    '/account/reset-password',
    '/account/advertiser-signup',
  ];
  css: any;

  loading = false;

  constructor(
    private router: Router,
    commonServices: CommonServices,
  ) {

    // @ts-ignore
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });

    this.css = '<style>h4.auth-title {\n' +
      '    color: red !important;\n' +
      '}</style>';

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let url = event.url;
        let isAuthPage = false;

        //generate class
        let urlClass = url.toString().replace('/', '-').substring(1);
        document.body.setAttribute('page', urlClass);


        this.authRoutes.forEach((link) => {
          if (url.includes(link)) {
            isAuthPage = true;
          }
        });
        if (isAuthPage) {
          document.body.classList.add("not-logged-in");
        } else {
          document.body.classList.remove("not-logged-in");
        }
      }
    });

    commonServices.updateSiteSettings();

  }
}
