import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-login1',
  templateUrl: './login1.component.html',
  styleUrls: ['./login1.component.scss']
})

/**
 * Login-1 component
 */
export class Login1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
