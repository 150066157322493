import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginationDataModel} from "../../core/models/pagination-data.model";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

/**
 * pagination component
 */
export class PaginationComponent implements OnInit, AfterViewInit {


  @Input() paginationData: PaginationDataModel;
  @Output() onPageChange = new EventEmitter<string>();


  constructor() {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }

  pageChange($event) {
    this.onPageChange.emit($event);
  }


}
